// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-all-cases-js": () => import("./../../../src/pages/all-cases.js" /* webpackChunkName: "component---src-pages-all-cases-js" */),
  "component---src-pages-all-items-js": () => import("./../../../src/pages/all-items.js" /* webpackChunkName: "component---src-pages-all-items-js" */),
  "component---src-pages-form-success-js": () => import("./../../../src/pages/form-success.js" /* webpackChunkName: "component---src-pages-form-success-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-questions-list-js": () => import("./../../../src/pages/questions-list.js" /* webpackChunkName: "component---src-pages-questions-list-js" */),
  "component---src-templates-case-article-js": () => import("./../../../src/templates/case-article.js" /* webpackChunkName: "component---src-templates-case-article-js" */),
  "component---src-templates-item-article-js": () => import("./../../../src/templates/item-article.js" /* webpackChunkName: "component---src-templates-item-article-js" */)
}

